import React from 'react';
import RBButton, { ButtonProps } from 'react-bootstrap/Button';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const Button = (props: Props & ButtonProps) => {
    const { children, className, ...rest } = props;

    return (
        <RBButton {...rest} className={`rounded-pill ${className}`}>
            {props.children}
        </RBButton>
    );
};

export default Button;
