import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Header } from '../components';
import { Firebase } from '../utils';

const Core = () => {
    useEffect(() => {
        Firebase.signInAnonymous().finally(() => null);

        return () => {
            Firebase.signOut().finally(() => null);
        };
    }, []);

    return (
        <>
            <Header />
            <div id="route-content">
                <Outlet />
            </div>
            <ToastContainer position="bottom-left" />
        </>
    );
};

export default Core;

export { default as Landing } from './Landing/Landing';
export { default as Generate } from './Generate/Generate';
export { default as Error } from './Error/Error';
