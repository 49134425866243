import { createBrowserRouter } from 'react-router-dom';

import Root, { Error, Landing } from '../routes';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Root />,
        errorElement: <Error />,
        children: [
            {
                path: '/',
                element: <Landing />,
            },
        ],
    },
]);
