import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';

import { ASSETS } from '../../assets';
import Button from '../Button/Button';

const Header = () => {
    return (
        <header>
            <Navbar expand="lg" className="py-4">
                <Container>
                    <Navbar.Brand href="/">
                        <img src={ASSETS.logo} alt="Brief.do logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse className="justify-content-end">
                        <Nav>
                            <Nav.Item className="ms-auto mt-4 mt-lg-0 ms-lg-0">
                                <Button className="header-main-btn rounded-pill">COMING SOON</Button>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default Header;
