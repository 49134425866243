import React, { useMemo } from 'react';
import ReactPlayer from 'react-player/file';
import { Form, InputGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { ASSETS } from '../../assets';
import { Button } from '../../components';
import { Firebase } from '../../utils';
import { useWindowSize } from '../../hooks';

import './Landing.scss';

const Landing = () => {
    const form = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: yup.object().shape({
            email: yup.string().email('Invalid email').required('Required email'),
        }),
        onSubmit: async (values) => {
            if (!values.email) {
                return;
            }

            const res = await Firebase.registerEmail(values.email);

            if (res.error) {
                toast.error(res.error);
            } else {
                toast.success('You have been successfully registered !');
            }
        },
    });

    const emailIsInvalid = useMemo(() => !!form.errors.email, [form.errors.email]);

    const winSizes = useWindowSize();

    const playerSizes = useMemo(() => {
        const screenWidth = winSizes.width;
        if (screenWidth < 460) {
            return {
                width: 256,
                height: 144,
            };
        } else if (screenWidth < 460) {
            return {
                width: 384,
                height: 216,
            };
        } else if (screenWidth < 660) {
            return {
                width: 426,
                height: 240,
            };
        } else {
            return {
                width: 640,
                height: 360,
            };
        }
    }, [winSizes.width]);

    return (
        <div id="landing" className="container d-flex flex-column align-items-center justify-content-center h-100">
            <h1 className="title mb-0">
                Unlock the potential of
                <br /> your sales teams.
            </h1>
            <h3 className="my-4 my-md-5 text-center">
                Learn from all your <span className="fw-bold subtitle-yellow">business interactions</span>
            </h3>
            <ReactPlayer
                url="media/brief_presentation_en.mp4"
                controls
                height={playerSizes.height}
                width={playerSizes.width}
            />
            <div className="mt-5">
                <Form onSubmit={form.handleSubmit} noValidate className="">
                    <Form.Group
                        className="d-flex flex-column flex-sm-row align-items-center"
                        controlId="formBasicEmail"
                    >
                        <InputGroup hasValidation>
                            <InputGroup.Text
                                className={`ps-4 pe-2 border-end-0 bg-white rounded-start-3 ${
                                    emailIsInvalid ? 'invalid' : ''
                                }`}
                            >
                                <img src={ASSETS.emailLogo} alt="email logo" />
                            </InputGroup.Text>
                            <Form.Control
                                className="email-registration-input border-start-0 rounded-end-3"
                                type="email"
                                placeholder="Your work email"
                                isInvalid={emailIsInvalid}
                                {...form.getFieldProps('email')}
                            />
                            <Form.Control.Feedback type="invalid">{form.errors.email}</Form.Control.Feedback>
                        </InputGroup>
                        <Button
                            type="submit"
                            className={`d-flex flex-row align-items-center justify-content-between ms-3 mt-3 mt-sm-0 email-registration-btn text-nowrap ${
                                emailIsInvalid ? 'mb-4' : ''
                            }`}
                        >
                            Sign up
                            <span className="ms-5">
                                <img alt="" src={ASSETS.arrowRight} />
                            </span>
                        </Button>
                    </Form.Group>
                </Form>
            </div>
        </div>
    );
};

export default Landing;
