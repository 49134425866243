// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, getAuth, signInAnonymously, signOut } from 'firebase/auth';
import {
    Firestore,
    getFirestore,
    collection,
    addDoc,
    DocumentData,
    CollectionReference,
    query,
    where,
    getDocs,
} from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyDeEmrKyww-kiF5v08s-W6wUf4zfOaDJqU',
    authDomain: 'brief-31c39.firebaseapp.com',
    projectId: 'brief-31c39',
    storageBucket: 'brief-31c39.appspot.com',
    messagingSenderId: '699670520496',
    appId: '1:699670520496:web:6ec15cae06744115d01175',
};

class Firebase {
    private static instance?: Firebase;

    private app?: FirebaseApp;
    private firestore?: Firestore;
    private auth?: Auth;

    private constructor() {
        if (process.env.NODE_ENV !== 'test') {
            this.app = initializeApp(firebaseConfig);
            this.firestore = getFirestore(this.app);
            this.auth = getAuth(this.app);
        }
    }

    public static getInstance(): Firebase {
        if (!this.instance) {
            this.instance = new Firebase();
        }

        return this.instance;
    }


    // Auth
    signInAnonymous = async (): Promise<void> => {
        if (this.auth) {
            signInAnonymously(this.auth).finally(() => console.log('signed in anonymously'));
        }
    };

    signOut = async (): Promise<void> => {
        if (this.auth) {
            signOut(this.auth).finally(() => null);
        }
    };

    // Firestore
    emailAlreadyExist = async (collection: CollectionReference<DocumentData, DocumentData>, email: string) => {
        const q = query(collection, where('email', '==', email));

        const querySnapshot = await getDocs(q);

        return !querySnapshot.empty;
    };

    registerEmail = async (email: string): Promise<{ error?: string }> => {
        if (this.firestore) {
            const emailsCollection = collection(this.firestore, 'emails');

            try {
                const emailAlreadyExist = await this.emailAlreadyExist(emailsCollection, email);

                if (!emailAlreadyExist) {
                    await addDoc(emailsCollection, {
                        email,
                    });

                    return { error: undefined };
                } else {
                    return { error: 'You are already registered.' };
                }
            } catch (e) {
                console.error(e);
                return { error: 'An error occured, try again later.' };
            }
        } else {
            return { error: 'Firestore not initialized' };
        }

    };
}

export default Firebase.getInstance();
