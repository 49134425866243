import { useRouteError } from 'react-router-dom';

import './Error.scss';

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p className="my-5">Sorry, an unexpected error has occurred.</p>
            <p>
                <i>{(error as any).statusText || (error as any).message || ''}</i>
            </p>
        </div>
    );
}
